import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = { class: "scroll-y mh-200px mh-lg-325px" }
const _hoisted_3 = { class: "d-flex align-items-center mb-5" }
const _hoisted_4 = { class: "symbol symbol-40px symbol-circle me-4" }
const _hoisted_5 = { class: "symbol-label bg-light" }
const _hoisted_6 = { class: "svg-icon svg-icon-2 svg-icon-primary" }
const _hoisted_7 = { class: "d-flex align-items-center mb-5" }
const _hoisted_8 = { class: "symbol symbol-40px symbol-circle me-4" }
const _hoisted_9 = { class: "symbol-label bg-light" }
const _hoisted_10 = { class: "svg-icon svg-icon-2 svg-icon-primary" }
const _hoisted_11 = { class: "d-flex align-items-center mb-5" }
const _hoisted_12 = { class: "symbol symbol-40px symbol-circle me-4" }
const _hoisted_13 = { class: "symbol-label bg-light" }
const _hoisted_14 = { class: "svg-icon svg-icon-2 svg-icon-primary" }
const _hoisted_15 = { class: "d-flex align-items-center mb-5" }
const _hoisted_16 = { class: "symbol symbol-40px symbol-circle me-4" }
const _hoisted_17 = { class: "symbol-label bg-light" }
const _hoisted_18 = { class: "svg-icon svg-icon-2 svg-icon-primary" }
const _hoisted_19 = { class: "d-flex align-items-center mb-5" }
const _hoisted_20 = { class: "symbol symbol-40px symbol-circle me-4" }
const _hoisted_21 = { class: "symbol-label bg-light" }
const _hoisted_22 = { class: "svg-icon svg-icon-2 svg-icon-primary" }
const _hoisted_23 = { class: "d-flex align-items-center mb-5" }
const _hoisted_24 = { class: "symbol symbol-40px symbol-circle me-4" }
const _hoisted_25 = { class: "symbol-label bg-light" }
const _hoisted_26 = { class: "svg-icon svg-icon-2 svg-icon-primary" }
const _hoisted_27 = { class: "d-flex align-items-center mb-5" }
const _hoisted_28 = { class: "symbol symbol-40px symbol-circle me-4" }
const _hoisted_29 = { class: "symbol-label bg-light" }
const _hoisted_30 = { class: "svg-icon svg-icon-2 svg-icon-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode("begin::Heading"),
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "d-flex flex-stack fw-bold mb-4" }, [
      _createCommentVNode("begin::Label"),
      _createElementVNode("span", { class: "text-muted fs-6 me-2" }, "Recently Searched:"),
      _createCommentVNode("end::Label")
    ], -1 /* HOISTED */)),
    _createCommentVNode("end::Heading"),
    _createCommentVNode("begin::Items"),
    _createElementVNode("div", _hoisted_2, [
      _createCommentVNode("begin::Item"),
      _createElementVNode("div", _hoisted_3, [
        _createCommentVNode("begin::Symbol"),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/electronics/elc004.svg" })
            ])
          ])
        ]),
        _createCommentVNode("end::Symbol"),
        _createCommentVNode("begin::Title"),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "d-flex flex-column" }, [
          _createElementVNode("a", {
            href: "#",
            class: "fs-6 text-gray-800 text-hover-primary fw-bold"
          }, "BoomApp by Keenthemes"),
          _createElementVNode("span", { class: "fs-7 text-muted fw-bold" }, "#45789")
        ], -1 /* HOISTED */)),
        _createCommentVNode("end::Title")
      ]),
      _createCommentVNode("end::Item"),
      _createCommentVNode("begin::Item"),
      _createElementVNode("div", _hoisted_7, [
        _createCommentVNode("begin::Symbol"),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, [
            _createElementVNode("span", _hoisted_10, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/graphs/gra001.svg" })
            ])
          ])
        ]),
        _createCommentVNode("end::Symbol"),
        _createCommentVNode("begin::Title"),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "d-flex flex-column" }, [
          _createElementVNode("a", {
            href: "#",
            class: "fs-6 text-gray-800 text-hover-primary fw-bold"
          }, "\"Kept API Project Meeting"),
          _createElementVNode("span", { class: "fs-7 text-muted fw-bold" }, "#84050")
        ], -1 /* HOISTED */)),
        _createCommentVNode("end::Title")
      ]),
      _createCommentVNode("end::Item"),
      _createCommentVNode("begin::Item"),
      _createElementVNode("div", _hoisted_11, [
        _createCommentVNode("begin::Symbol"),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("span", _hoisted_13, [
            _createElementVNode("span", _hoisted_14, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/graphs/gra006.svg" })
            ])
          ])
        ]),
        _createCommentVNode("end::Symbol"),
        _createCommentVNode("begin::Title"),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "d-flex flex-column" }, [
          _createElementVNode("a", {
            href: "#",
            class: "fs-6 text-gray-800 text-hover-primary fw-bold"
          }, "\"KPI Monitoring App Launch"),
          _createElementVNode("span", { class: "fs-7 text-muted fw-bold" }, "#84250")
        ], -1 /* HOISTED */)),
        _createCommentVNode("end::Title")
      ]),
      _createCommentVNode("end::Item"),
      _createCommentVNode("begin::Item"),
      _createElementVNode("div", _hoisted_15, [
        _createCommentVNode("begin::Symbol"),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("span", _hoisted_17, [
            _createElementVNode("span", _hoisted_18, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/graphs/gra002.svg" })
            ])
          ])
        ]),
        _createCommentVNode("end::Symbol"),
        _createCommentVNode("begin::Title"),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "d-flex flex-column" }, [
          _createElementVNode("a", {
            href: "#",
            class: "fs-6 text-gray-800 text-hover-primary fw-bold"
          }, "Project Reference FAQ"),
          _createElementVNode("span", { class: "fs-7 text-muted fw-bold" }, "#67945")
        ], -1 /* HOISTED */)),
        _createCommentVNode("end::Title")
      ]),
      _createCommentVNode("end::Item"),
      _createCommentVNode("begin::Item"),
      _createElementVNode("div", _hoisted_19, [
        _createCommentVNode("begin::Symbol"),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("span", _hoisted_21, [
            _createElementVNode("span", _hoisted_22, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/communication/com010.svg" })
            ])
          ])
        ]),
        _createCommentVNode("end::Symbol"),
        _createCommentVNode("begin::Title"),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "d-flex flex-column" }, [
          _createElementVNode("a", {
            href: "#",
            class: "fs-6 text-gray-800 text-hover-primary fw-bold"
          }, "\"FitPro App Development"),
          _createElementVNode("span", { class: "fs-7 text-muted fw-bold" }, "#84250")
        ], -1 /* HOISTED */)),
        _createCommentVNode("end::Title")
      ]),
      _createCommentVNode("end::Item"),
      _createCommentVNode("begin::Item"),
      _createElementVNode("div", _hoisted_23, [
        _createCommentVNode("begin::Symbol"),
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("span", _hoisted_25, [
            _createElementVNode("span", _hoisted_26, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/finance/fin001.svg" })
            ])
          ])
        ]),
        _createCommentVNode("end::Symbol"),
        _createCommentVNode("begin::Title"),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "d-flex flex-column" }, [
          _createElementVNode("a", {
            href: "#",
            class: "fs-6 text-gray-800 text-hover-primary fw-bold"
          }, "Shopix Mobile App"),
          _createElementVNode("span", { class: "fs-7 text-muted fw-bold" }, "#45690")
        ], -1 /* HOISTED */)),
        _createCommentVNode("end::Title")
      ]),
      _createCommentVNode("end::Item"),
      _createCommentVNode("begin::Item"),
      _createElementVNode("div", _hoisted_27, [
        _createCommentVNode("begin::Symbol"),
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("span", _hoisted_29, [
            _createElementVNode("span", _hoisted_30, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/graphs/gra002.svg" })
            ])
          ])
        ]),
        _createCommentVNode("end::Symbol"),
        _createCommentVNode("begin::Title"),
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "d-flex flex-column" }, [
          _createElementVNode("a", {
            href: "#",
            class: "fs-6 text-gray-800 text-hover-primary fw-bold"
          }, "\"Landing UI Design\" Launch"),
          _createElementVNode("span", { class: "fs-7 text-muted fw-bold" }, "#24005")
        ], -1 /* HOISTED */)),
        _createCommentVNode("end::Title")
      ]),
      _createCommentVNode("end::Item")
    ]),
    _createCommentVNode("end::Items")
  ]))
}