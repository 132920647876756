import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-toolbar d-flex align-items-stretch flex-shrink-0" }
const _hoisted_3 = { class: "d-flex align-items-stretch ms-1 ms-lg-3" }
const _hoisted_4 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_5 = { class: "svg-icon svg-icon-2" }
const _hoisted_6 = { class: "card-body py-3" }
const _hoisted_7 = { class: "table-responsive" }
const _hoisted_8 = { class: "table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" }
const _hoisted_9 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary fs-6"
}
const _hoisted_10 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
}
const _hoisted_11 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_12 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
}
const _hoisted_13 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_14 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
}
const _hoisted_15 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_16 = { class: "text-dark fw-bolder text-hover-primary fs-6" }
const _hoisted_17 = { class: "text-end" }
const _hoisted_18 = {
  href: "#",
  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
}
const _hoisted_19 = { class: "svg-icon svg-icon-3" }
const _hoisted_20 = {
  href: "#",
  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
}
const _hoisted_21 = { class: "svg-icon svg-icon-3" }
const _hoisted_22 = {
  href: "#",
  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
}
const _hoisted_23 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTSearch = _resolveComponent("KTSearch")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown2 = _resolveComponent("Dropdown2")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Tables Widget 13"),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.widgetClasses, "card"])
    }, [
      _createCommentVNode("begin::Header"),
      _createElementVNode("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
          _createElementVNode("span", { class: "card-label fw-bolder fs-3 mb-1" }, "Bins"),
          _createElementVNode("span", { class: "text-muted mt-1 fw-bold fs-7" }, "Here is List Of Bins")
        ], -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_2, [
          _createCommentVNode("begin::Search"),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_KTSearch)
          ]),
          _createCommentVNode("end::Search"),
          _createCommentVNode("begin::Menu"),
          _createElementVNode("button", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
            ])
          ]),
          _createVNode(_component_Dropdown2),
          _createCommentVNode("end::Menu")
        ])
      ]),
      _createCommentVNode("end::Header"),
      _createCommentVNode("begin::Body"),
      _createElementVNode("div", _hoisted_6, [
        _createCommentVNode("begin::Table container"),
        _createElementVNode("div", _hoisted_7, [
          _createCommentVNode("begin::Table"),
          _createElementVNode("table", _hoisted_8, [
            _createCommentVNode("begin::Table head"),
            _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
              _createElementVNode("tr", { class: "fw-bolder text-muted" }, [
                _createElementVNode("th", { class: "w-25px" }, [
                  _createElementVNode("div", { class: "form-check form-check-sm form-check-custom form-check-solid" }, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: "1",
                      "data-kt-check": "true",
                      "data-kt-check-target": ".widget-13-check"
                    })
                  ])
                ]),
                _createElementVNode("th", { class: "min-w-150px" }, "Order Id"),
                _createElementVNode("th", { class: "min-w-140px" }, "Country"),
                _createElementVNode("th", { class: "min-w-120px" }, "Date"),
                _createElementVNode("th", { class: "min-w-120px" }, "Company"),
                _createElementVNode("th", { class: "min-w-120px" }, "Total"),
                _createElementVNode("th", { class: "min-w-120px" }, "Status"),
                _createElementVNode("th", { class: "min-w-100px text-end" }, "Actions")
              ])
            ], -1 /* HOISTED */)),
            _createCommentVNode("end::Table head"),
            _createCommentVNode("begin::Table body"),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                  _cache[1] || (_cache[1] = _createElementVNode("td", null, [
                    _createElementVNode("div", { class: "form-check form-check-sm form-check-custom form-check-solid" }, [
                      _createElementVNode("input", {
                        class: "form-check-input widget-13-check",
                        type: "checkbox",
                        value: "1"
                      })
                    ])
                  ], -1 /* HOISTED */)),
                  _createElementVNode("td", null, [
                    _createElementVNode("a", _hoisted_9, _toDisplayString(item.orderid), 1 /* TEXT */)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("a", _hoisted_10, _toDisplayString(item.company.name), 1 /* TEXT */),
                    _createElementVNode("span", _hoisted_11, "Code: " + _toDisplayString(item.country.code), 1 /* TEXT */)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("a", _hoisted_12, _toDisplayString(item.date.value), 1 /* TEXT */),
                    _createElementVNode("span", _hoisted_13, "Code: " + _toDisplayString(item.date.remarks), 1 /* TEXT */)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("a", _hoisted_14, _toDisplayString(item.company.name), 1 /* TEXT */),
                    _createElementVNode("span", _hoisted_15, _toDisplayString(item.company.fields), 1 /* TEXT */)
                  ]),
                  _createElementVNode("td", _hoisted_16, _toDisplayString(item.total), 1 /* TEXT */),
                  _createElementVNode("td", null, [
                    _createElementVNode("span", {
                      class: _normalizeClass([`badge-light-${item.status.color}`, "badge"])
                    }, _toDisplayString(item.status.label), 3 /* TEXT, CLASS */)
                  ]),
                  _createElementVNode("td", _hoisted_17, [
                    _createElementVNode("a", _hoisted_18, [
                      _createElementVNode("span", _hoisted_19, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen019.svg" })
                      ])
                    ]),
                    _createElementVNode("a", _hoisted_20, [
                      _createElementVNode("span", _hoisted_21, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/art/art005.svg" })
                      ])
                    ]),
                    _createElementVNode("a", _hoisted_22, [
                      _createElementVNode("span", _hoisted_23, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen027.svg" })
                      ])
                    ])
                  ])
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _createCommentVNode("end::Table body")
          ]),
          _createCommentVNode("end::Table")
        ]),
        _createCommentVNode("end::Table container")
      ]),
      _createCommentVNode("begin::Body")
    ], 2 /* CLASS */),
    _createCommentVNode("end::Tables Widget 13")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}