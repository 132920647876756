import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "pt-10 pb-10" }
const _hoisted_3 = { class: "svg-icon svg-icon-4x opacity-50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Empty"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Icon"),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/files/fil024.svg" })
        ])
      ]),
      _createCommentVNode("end::Icon"),
      _createCommentVNode("begin::Message"),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "pb-15 fw-bold" }, [
        _createElementVNode("h3", { class: "text-gray-600 fs-5 mb-2" }, "No result found"),
        _createElementVNode("div", { class: "text-muted fs-7" }, "Please try again with a different query")
      ], -1 /* HOISTED */)),
      _createCommentVNode("end::Message")
    ]),
    _createCommentVNode("end::Empty")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}