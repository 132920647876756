import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "scroll-y mh-200px mh-lg-325px" }
const _hoisted_2 = {
  href: "#",
  class: "d-flex text-dark text-hover-primary align-items-center mb-5"
}
const _hoisted_3 = { class: "symbol symbol-40px symbol-circle me-4" }
const _hoisted_4 = { class: "symbol-label bg-light" }
const _hoisted_5 = { class: "svg-icon svg-icon-2 svg-icon-primary" }
const _hoisted_6 = {
  href: "#",
  class: "d-flex text-dark text-hover-primary align-items-center mb-5"
}
const _hoisted_7 = { class: "symbol symbol-40px symbol-circle me-4" }
const _hoisted_8 = { class: "symbol-label bg-light" }
const _hoisted_9 = { class: "svg-icon svg-icon-2 svg-icon-primary" }
const _hoisted_10 = {
  href: "#",
  class: "d-flex text-dark text-hover-primary align-items-center mb-5"
}
const _hoisted_11 = { class: "symbol symbol-40px symbol-circle me-4" }
const _hoisted_12 = { class: "symbol-label bg-light" }
const _hoisted_13 = { class: "svg-icon svg-icon-2 svg-icon-primary" }
const _hoisted_14 = {
  href: "#",
  class: "d-flex text-dark text-hover-primary align-items-center mb-5"
}
const _hoisted_15 = { class: "symbol symbol-40px symbol-circle me-4" }
const _hoisted_16 = { class: "symbol-label bg-light" }
const _hoisted_17 = { class: "svg-icon svg-icon-2 svg-icon-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createCommentVNode("begin::Items"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Category title"),
      _cache[4] || (_cache[4] = _createElementVNode("h3", { class: "fs-5 text-muted m-0 pb-5" }, "Users", -1 /* HOISTED */)),
      _createCommentVNode("end::Category title"),
      _createCommentVNode("begin::Item"),
      _cache[5] || (_cache[5] = _createStaticVNode("<a href=\"#\" class=\"d-flex text-dark text-hover-primary align-items-center mb-5\"><!--begin::Symbol--><div class=\"symbol symbol-40px symbol-circle me-4\"><img src=\"media/avatars/150-1.jpg\" alt=\"\"></div><!--end::Symbol--><!--begin::Title--><div class=\"d-flex flex-column justify-content-start fw-bold\"><span class=\"fs-6 fw-bold\">Karina Clark</span><span class=\"fs-7 fw-bold text-muted\">Marketing Manager</span></div><!--end::Title--></a>", 1)),
      _createCommentVNode("end::Item"),
      _createCommentVNode("begin::Item"),
      _cache[6] || (_cache[6] = _createStaticVNode("<a href=\"#\" class=\"d-flex text-dark text-hover-primary align-items-center mb-5\"><!--begin::Symbol--><div class=\"symbol symbol-40px symbol-circle me-4\"><img src=\"media/avatars/150-3.jpg\" alt=\"\"></div><!--end::Symbol--><!--begin::Title--><div class=\"d-flex flex-column justify-content-start fw-bold\"><span class=\"fs-6 fw-bold\">Olivia Bold</span><span class=\"fs-7 fw-bold text-muted\">Software Engineer</span></div><!--end::Title--></a>", 1)),
      _createCommentVNode("end::Item"),
      _createCommentVNode("begin::Item"),
      _cache[7] || (_cache[7] = _createStaticVNode("<a href=\"#\" class=\"d-flex text-dark text-hover-primary align-items-center mb-5\"><!--begin::Symbol--><div class=\"symbol symbol-40px symbol-circle me-4\"><img src=\"media/avatars/150-8.jpg\" alt=\"\"></div><!--end::Symbol--><!--begin::Title--><div class=\"d-flex flex-column justify-content-start fw-bold\"><span class=\"fs-6 fw-bold\">Ana Clark</span><span class=\"fs-7 fw-bold text-muted\">UI/UX Designer</span></div><!--end::Title--></a>", 1)),
      _createCommentVNode("end::Item"),
      _createCommentVNode("begin::Item"),
      _cache[8] || (_cache[8] = _createStaticVNode("<a href=\"#\" class=\"d-flex text-dark text-hover-primary align-items-center mb-5\"><!--begin::Symbol--><div class=\"symbol symbol-40px symbol-circle me-4\"><img src=\"media/avatars/150-11.jpg\" alt=\"\"></div><!--end::Symbol--><!--begin::Title--><div class=\"d-flex flex-column justify-content-start fw-bold\"><span class=\"fs-6 fw-bold\">Nick Pitola</span><span class=\"fs-7 fw-bold text-muted\">Art Director</span></div><!--end::Title--></a>", 1)),
      _createCommentVNode("end::Item"),
      _createCommentVNode("begin::Item"),
      _cache[9] || (_cache[9] = _createStaticVNode("<a href=\"#\" class=\"d-flex text-dark text-hover-primary align-items-center mb-5\"><!--begin::Symbol--><div class=\"symbol symbol-40px symbol-circle me-4\"><img src=\"media/avatars/150-12.jpg\" alt=\"\"></div><!--end::Symbol--><!--begin::Title--><div class=\"d-flex flex-column justify-content-start fw-bold\"><span class=\"fs-6 fw-bold\">Edward Kulnic</span><span class=\"fs-7 fw-bold text-muted\">System Administrator</span></div><!--end::Title--></a>", 1)),
      _createCommentVNode("end::Item"),
      _createCommentVNode("begin::Category title"),
      _cache[10] || (_cache[10] = _createElementVNode("h3", { class: "fs-5 text-muted m-0 pt-5 pb-5" }, "Customers", -1 /* HOISTED */)),
      _createCommentVNode("end::Category title"),
      _createCommentVNode("begin::Item"),
      _cache[11] || (_cache[11] = _createStaticVNode("<a href=\"#\" class=\"d-flex text-dark text-hover-primary align-items-center mb-5\"><!--begin::Symbol--><div class=\"symbol symbol-40px symbol-circle me-4\"><span class=\"symbol-label bg-light\"><img class=\"w-20px h-20px\" src=\"media/svg/brand-logos/volicity-9.svg\" alt=\"\"></span></div><!--end::Symbol--><!--begin::Title--><div class=\"d-flex flex-column justify-content-start fw-bold\"><span class=\"fs-6 fw-bold\">Company Rbranding</span><span class=\"fs-7 fw-bold text-muted\">UI Design</span></div><!--end::Title--></a>", 1)),
      _createCommentVNode("end::Item"),
      _createCommentVNode("begin::Item"),
      _cache[12] || (_cache[12] = _createStaticVNode("<a href=\"#\" class=\"d-flex text-dark text-hover-primary align-items-center mb-5\"><!--begin::Symbol--><div class=\"symbol symbol-40px symbol-circle me-4\"><span class=\"symbol-label bg-light\"><img class=\"w-20px h-20px\" src=\"media/svg/brand-logos/tvit.svg\" alt=\"\"></span></div><!--end::Symbol--><!--begin::Title--><div class=\"d-flex flex-column justify-content-start fw-bold\"><span class=\"fs-6 fw-bold\">Company Re-branding</span><span class=\"fs-7 fw-bold text-muted\">Web Development</span></div><!--end::Title--></a>", 1)),
      _createCommentVNode("end::Item"),
      _createCommentVNode("begin::Item"),
      _cache[13] || (_cache[13] = _createStaticVNode("<a href=\"#\" class=\"d-flex text-dark text-hover-primary align-items-center mb-5\"><!--begin::Symbol--><div class=\"symbol symbol-40px symbol-circle me-4\"><span class=\"symbol-label bg-light\"><img class=\"w-20px h-20px\" src=\"media/svg/misc/infography.svg\" alt=\"\"></span></div><!--end::Symbol--><!--begin::Title--><div class=\"d-flex flex-column justify-content-start fw-bold\"><span class=\"fs-6 fw-bold\">Business Analytics App</span><span class=\"fs-7 fw-bold text-muted\">Administration</span></div><!--end::Title--></a>", 1)),
      _createCommentVNode("end::Item"),
      _createCommentVNode("begin::Item"),
      _cache[14] || (_cache[14] = _createStaticVNode("<a href=\"#\" class=\"d-flex text-dark text-hover-primary align-items-center mb-5\"><!--begin::Symbol--><div class=\"symbol symbol-40px symbol-circle me-4\"><span class=\"symbol-label bg-light\"><img class=\"w-20px h-20px\" src=\"media/svg/brand-logos/leaf.svg\" alt=\"\"></span></div><!--end::Symbol--><!--begin::Title--><div class=\"d-flex flex-column justify-content-start fw-bold\"><span class=\"fs-6 fw-bold\">EcoLeaf App Launch</span><span class=\"fs-7 fw-bold text-muted\">Marketing</span></div><!--end::Title--></a>", 1)),
      _createCommentVNode("end::Item"),
      _createCommentVNode("begin::Item"),
      _cache[15] || (_cache[15] = _createStaticVNode("<a href=\"#\" class=\"d-flex text-dark text-hover-primary align-items-center mb-5\"><!--begin::Symbol--><div class=\"symbol symbol-40px symbol-circle me-4\"><span class=\"symbol-label bg-light\"><img class=\"w-20px h-20px\" src=\"media/svg/brand-logos/tower.svg\" alt=\"\"></span></div><!--end::Symbol--><!--begin::Title--><div class=\"d-flex flex-column justify-content-start fw-bold\"><span class=\"fs-6 fw-bold\">Tower Group Website</span><span class=\"fs-7 fw-bold text-muted\">Google Adwords</span></div><!--end::Title--></a>", 1)),
      _createCommentVNode("end::Item"),
      _createCommentVNode("begin::Category title"),
      _cache[16] || (_cache[16] = _createElementVNode("h3", { class: "fs-5 text-muted m-0 pt-5 pb-5" }, "Projects", -1 /* HOISTED */)),
      _createCommentVNode("end::Category title"),
      _createCommentVNode("begin::Item"),
      _createElementVNode("a", _hoisted_2, [
        _createCommentVNode("begin::Symbol"),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen005.svg" })
            ])
          ])
        ]),
        _createCommentVNode("end::Symbol"),
        _createCommentVNode("begin::Title"),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "d-flex flex-column" }, [
          _createElementVNode("span", { class: "fs-6 fw-bold" }, "Si-Fi Project by AU Themes"),
          _createElementVNode("span", { class: "fs-7 fw-bold text-muted" }, "#45670")
        ], -1 /* HOISTED */)),
        _createCommentVNode("end::Title")
      ]),
      _createCommentVNode("end::Item"),
      _createCommentVNode("begin::Item"),
      _createElementVNode("a", _hoisted_6, [
        _createCommentVNode("begin::Symbol"),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, [
            _createElementVNode("span", _hoisted_9, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen032.svg" })
            ])
          ])
        ]),
        _createCommentVNode("end::Symbol"),
        _createCommentVNode("begin::Title"),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "d-flex flex-column" }, [
          _createElementVNode("span", { class: "fs-6 fw-bold" }, "Shopix Mobile App Planning"),
          _createElementVNode("span", { class: "fs-7 fw-bold text-muted" }, "#45690")
        ], -1 /* HOISTED */)),
        _createCommentVNode("end::Title")
      ]),
      _createCommentVNode("end::Item"),
      _createCommentVNode("begin::Item"),
      _createElementVNode("a", _hoisted_10, [
        _createCommentVNode("begin::Symbol"),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("span", _hoisted_12, [
            _createElementVNode("span", _hoisted_13, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/communication/com012.svg" })
            ])
          ])
        ]),
        _createCommentVNode("end::Symbol"),
        _createCommentVNode("begin::Title"),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "d-flex flex-column" }, [
          _createElementVNode("span", { class: "fs-6 fw-bold" }, "Finance Monitoring SAAS Discussion"),
          _createElementVNode("span", { class: "fs-7 fw-bold text-muted" }, "#21090")
        ], -1 /* HOISTED */)),
        _createCommentVNode("end::Title")
      ]),
      _createCommentVNode("end::Item"),
      _createCommentVNode("begin::Item"),
      _createElementVNode("a", _hoisted_14, [
        _createCommentVNode("begin::Symbol"),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("span", _hoisted_16, [
            _createElementVNode("span", _hoisted_17, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/communication/com006.svg" })
            ])
          ])
        ]),
        _createCommentVNode("end::Symbol"),
        _createCommentVNode("begin::Title"),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "d-flex flex-column" }, [
          _createElementVNode("span", { class: "fs-6 fw-bold" }, "Dashboard Analitics Launch"),
          _createElementVNode("span", { class: "fs-7 fw-bold text-muted" }, "#34560")
        ], -1 /* HOISTED */)),
        _createCommentVNode("end::Title")
      ]),
      _createCommentVNode("end::Item")
    ]),
    _createCommentVNode("end::Items")
  ]))
}